import React from 'react';
import ContentPage from '../../components/ContentPage';
import PageImage from '../../components/PageImage';

import image from '../../images/undraw_website_builder.svg';

function ServicesWebsitesPage() {

    return <ContentPage>
        <h1>Websites</h1>
        <p>Although our key strength is building interactive web applications, at Coralpeak Systems we can also build 
            traditional websites. If you need a place to tell the world about your company, or somewhere for 
            interaction with your customers and stakeholders, we can build a site to help you do it.</p>
        <PageImage image={image} alt='Website development' leftAlign={true} />
        <p>We will work with you to understand your objectives and help you to capture the opportunities that a good 
            website can achieve. Whether it just a simple website that you need, something with user interaction, an 
            eCommerce platform to sell your products and services - or a combination of all of these - we can guide 
            you through the process of building the site and deliver the optimum results.</p>
    </ContentPage>

}

export default ServicesWebsitesPage;